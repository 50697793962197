import { useEffect, useState } from "react"
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import Progress from "../../components/Progress/sca";
import countriesData from "../../components/json/countriesStates.json";
import jsonData from '../../components/json/accountsType.json';
import { toast } from "react-toastify";
import loader from "../../assets/images/loader.gif";
import IntlTelInput from 'react-intl-tel-input';
import { PiExclamationMarkDuotone } from "react-icons/pi";
import PasswordValidation from "../Form/passwordValidation";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Trans, useTranslation } from 'react-i18next';
// import ReCAPTCHA from "react-google-recaptcha";

import { Button, CustomForm } from "../Form/style";
import 'react-intl-tel-input/dist/main.css';
import '.././Form/style.css';
import '../../index.css';
import DirectorDetails from "./DirectorDetails";
import BeneficialOwner from "./BeneficialOwner";
import AuthorizedSignatory from "./AuthorizedSignatory";

export const CorporateForm = ({ entity }) => {
    const [refValue, setRefValue] = useState('');
    const [ptrValue, setPtrValue] = useState('');
    const [insertBlock, setInsertBlock] = useState([0]);
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [emailError ,setEmailError] = useState(0);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [countryList, setCountriesList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [platForm, setPlatForm] = useState('MT4');
    const [utmMediumValue, setUtmMediumValue] = useState('');
    const [singleClick, setSingleClick] = useState(false);
    const [checkEmail, setCheckEmail] = useState(false);
    const [checkBox, setCheckBox] = useState('');
    const [filteredAccountTypes, setFilteredAccountTypes] = useState('');
    const [imageList, setImageList] = useState([]);

    const [directors, setDirectors] = useState([
        { fullName: '', phone: '', address: '', tin: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }
    ]);

    const [beneficialOwners, setBeneficialOwners] = useState([
        { title: '', fullName: '', jobTitle: '', dob: '', tin: '', holdings: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }
    ]);

    const [authorizedSignatory, setAuthorizedSignatory] = useState([
        { fullName: '', jobTitle: '', email: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }
    ]);

    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({
        step1: { agentId: '', parentId: '', entity: 'FSA', callStatus: 'New Lead', firstName: '', email: '', phone: '', accountType: '', accountTypeCurrency: '', password: '', confirmPassword: '', country: '', countryCode: '', platform: 'MT4', utmMedium: utmMediumValue, date: '', corporateLicense: '', tradingName: '', natureOfBusiness: '', address: '', tradingAddress: '', companyWebsite: '', taxResidency: '', tin: '', regulator: false, regulatorName: '', regulatorNumber: '', leiNo: '', giin: '' },
        step2: { liquidFinancial: '', companyDeclared: Boolean, declaredNote: '', companyRecord: Boolean, recordNote: '', annualTurover: '', accumulatedWealth : '', thirdParty: Boolean, thirdPartyNote: '', fundsAvailable: '', initialDeposit: '', netAssets: '', accountName: '', bankName: '', sortCode: '', bankAccountNo: '', swift: ''}
    });

    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, email: false, phone: false, accountType: false, password: false, confirmPassword: false, country: false, countryCode: false, platform: false, utmMedium: false, date: false, corporateLicense: false, tradingName: false, natureOfBusiness: false, address: false, tradingAddress: false, companyWebsite: false, taxResidency: false, tin: false, regulator: false, regulatorName: false, regulatorNumber: false, leiNo: false, giin: false
        },
        step2: { 
            fullName: false, phone: false, address: false, tinDirector: false, proofOfIdentity: false, proofOfIdentityBack: false, resProofOfAddress: false, 
        },

    });

    useEffect(() => {
        setFilteredAccountTypes(filterAccountTypes('MT4'));
    }, [refValue]);

    useEffect(() => {

        if (entity === 'FSC') {
            const removeCountry = ['AFGHANISTAN', 'CUBA', 'GHANA', 'IRAN', 'LIBYA', 'MYANMAR', 'PANAMA', 'SOUNTH SUDAN', 'SYRIA', 'USA', 'YEMEN'];
            const filterCountry = countriesData.filter((country) => !removeCountry.includes(country.name));
            setCountriesList(filterCountry);
        } else if (entity === 'SCA') {
            const filterCountry = countriesData.filter((country) => country.name === "UNITED ARAB EMIRATES");
            setCountriesList(filterCountry);
        } else {
            setCountriesList(countriesData);
        }

    }, [entity])

    const data = {
        //step1
        agentId: '33',
        parentId: '',
        entity: entity,
        firstName: formData.step1.firstName,
        email: formData.step1.email,
        platform: formData.step1.platform,
        accountType: formData.step1.accountType,
        accountTypeCurrency: formData.step1.accountTypeCurrency,
        country: formData.step1.country,
        countryCode: formData.step1.countryCode,
        phone: formData.step1.phone,
        password: formData.step1.password,
        utmMedium: '',
        date: formData.step1.date,
        corporateLicense: formData.step1.corporateLicense,
        tradingName: formData.step1.tradingName,
        natureOfBusiness: formData.step1.natureOfBusiness,
        address: formData.step1.address,
        tradingAddress: formData.step1.tradingAddress,
        companyWebsite: formData.step1.companyWebsite,
        taxResidency: formData.step1.taxResidency,
        tin: formData.step1.tin,
        regulator: formData.step1.regulator,
        regulatorName: formData.step1.regulatorName,
        regulatorNumber: formData.step1.regulatorNumber,
        leiNo: formData.step1.leiNo,
        giin: formData.step1.giin,
    }

    const updateListFunction = (key, files) => {

        // Check if the key already exists in the imageList
        const existingIndex = imageList.findIndex(entry => entry.key === key);

        if (existingIndex !== -1) {
            // If the key already exists, update its value
            const updatedList = [...imageList];
            updatedList[existingIndex].value = files;
            setImageList(updatedList);

        } else {
            // If the key does not exist, add it as a new entry
            const newValue = {
                key,
                value: files
            };

            const updatedList = [...imageList, newValue];
            setImageList(updatedList);
        }
    };

    const filterAccountTypes = (platform) => {
        if (ptrValue && refValue !== 38) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 1))
        } else if (refValue === 38) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 2))
        } else {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 1))
        }
    };

    const accountTypeValue = (platform) => {
        // Determine accountType based on platform
        const accountType = platform === 'MT5' ? '2' : '14';

        // Update form data
        setFormData((prevFormData) => ({
            ...prevFormData,
            step1: {
                ...prevFormData.step1,
                accountType,
                accountTypeCurrency: 'USD'
            },
        }));

    }

    const validatePass = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#&$]).{8,12}$/;
        return regex.test(password);
    }

    const validatePassword = (password) => {
        const isValid = validatePass(password);
        if (isValid) {
            return true;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                password: !isValid,
            },
        }));
    };


    const isStep1Valid = () => {
        if (!validatePassword(formData.step1.password)) {
            toast.error("Password must be upper, lower and symbols($,&,@,#)");
            return false;
        }
        if (!(formData.step1.password === formData.step1.confirmPassword)) {
            toast.error("Password and confirm password must match");
            return false;
        }
        const { firstName, email, phone, accountType, password, confirmPassword, country, date, corporateLicense, tradingName, natureOfBusiness, address, tradingAddress, companyWebsite, taxResidency, tin, regulator, regulatorName, regulatorNumber, leiNo, giin } = formData.step1;

        // Check each field individually and set their validity
        const isfirstNameValid = firstName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isphoneValid = phone.trim() !== '';
        const isAccountTypeValid = accountType !== '';
        const isPasswordValid = password.trim() !== '';
        const isConfirmPasswordValid = confirmPassword.trim() !== '' && password.trim() === confirmPassword.trim();
        const isCountryValid = country !== '';
        const isDate = date.trim() !== '';
        const isCorporateLicense = corporateLicense.trim() !== '';
        const isTradingName = tradingName.trim() !== '';
        const isNatureOfBusiness = natureOfBusiness.trim() !== '';
        const isAddress = address.trim() !== '';
        const isTradingAddress = tradingAddress.trim() !== '';
        const isCompanyWebsite = companyWebsite.trim() !== '';
        const isTaxResidency = taxResidency.trim() !== '';
        const isTin = tin.trim() !== '';
        const isRegulator = regulator === true || regulator === false;
        const isRegulatorName = regulatorName.trim() !== '';
        const isRegulatorNumber = regulatorNumber.trim() !== '';
        const isLeiNo = leiNo.trim() !== '';
        const isGiin = giin.trim() !== ''

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isphoneValid,
                accountType: !isAccountTypeValid,
                password: !isPasswordValid,
                confirmPassword: !isConfirmPasswordValid,
                country: !isCountryValid,
                date: !isDate,
                corporateLicense: !isCorporateLicense,
                tradingName: !isTradingName,
                natureOfBusiness: !isNatureOfBusiness,
                address: !isAddress,
                tradingAddress: !isTradingAddress,
                companyWebsite: !isCompanyWebsite,
                taxResidency: !isTaxResidency,
                regulatorName: isRegulator && !isRegulatorName,
                regulatorNumber: isRegulator && !isRegulatorNumber,
                tin: !isTin,
                leiNo: !isLeiNo,
                giin: !isGiin
            }
        }));


        // Return true if all fields are valid, false otherwise
        const validTrue = isRegulator !== false ? (isfirstNameValid && isEmailValid  && isphoneValid &&
            isAccountTypeValid && isPasswordValid && isConfirmPasswordValid && isCountryValid && isDate && isCorporateLicense && 
            isTradingName && isTradingAddress && isNatureOfBusiness) :
            (isfirstNameValid && isEmailValid  && isphoneValid &&
            isAccountTypeValid && isPasswordValid && isConfirmPasswordValid && isCountryValid && isDate && isCorporateLicense && 
            isTradingName && isTradingAddress && isNatureOfBusiness && isRegulatorName && isRegulatorNumber);

        if (!validTrue) {
            toast.error("Please fill all the fields!");
            return false;
        }

        return true;
    };

    const isStep2Valid = () => {
        let isValid = true;
    
        directors.forEach((director, index) => {
            const {
                fullName,
                phone,
                address,
                tin,
                proofOfIdentity,
                proofOfIdentityBack,
                resProofOfAddress,
            } = director;
    
            // Check required fields
            if (!fullName || !phone || !address || !tin) {
                isValid = false;
                toast.error(`Please fill all required fields for director.`);
            }
    
            // Check if any uploaded files are missing
            const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress];
            if (proofOfIdentity === '') {
                isValid = false;
                toast.error(`Please upload all required files.`);
            }
    
            // Check if uploaded files are the same
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        isValid = false;
                        toast.error(`Uploaded files cannot be the same for director.`);
                    }
                }
            }
        });
    
        if (isValid) {
            return true;
        }
        return isValid;
    };
    
    const isStep3Valid = () => {
        let isValid = true;
    
        beneficialOwners.forEach((beneficialOwner, index) => {
            const {
                title,
                fullName,
                jobTitle,
                dob,
                tin,
                holdings,
                proofOfIdentity,
                proofOfIdentityBack,
                resProofOfAddress,
            } = beneficialOwner;
    
            // Check required fields
            if (!title || !fullName || !jobTitle || !dob || !holdings || !tin) {
                isValid = false;
                toast.error(`Please fill all required fields for director.`);
            }
    
            // Check if any uploaded files are missing
            const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress];
            if (proofOfIdentity === '') {
                isValid = false;
                toast.error(`Please upload all required files.`);
            }
    
            // Check if uploaded files are the same
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        isValid = false;
                        toast.error(`Uploaded files cannot be the same for director.`);
                    }
                }
            }
        });
    
        if (isValid) {
            return true;
        }
        return isValid;
    };

    
    const formValueChange = (step, name, value) => {
        const englishRegex = /^[a-zA-Z0-9\s.,'?!@#$%^&:*()_+=-]*$/;
        const uploadedFileNames = [
            'proofOfIdentity',
            'proofOfIdentityBack',
            'resProofOfAddress',
            'sourceOfIncome',
            'additional',
            'powerOfAttorney'
        ];

        const updateFormData = (newValue) => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: newValue,
                },
            }));
        };

        const validateEmail = (email) => {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setEmailError(emailPattern.test(email) ? 0 : 1);
        };

        const handlePhoneNumber = (phone) => {
            const sanitizedPhone = phone.replace(/[^\d]/g, '');
            updateFormData(sanitizedPhone);
        };

        const handleFormValueChange = () => {
            if (name === 'email') {
                validateEmail(value);
                updateFormData(value);
            } else if (name === 'phone') {
                handlePhoneNumber(value);
            } else if (name === 'dob' || englishRegex.test(value) || uploadedFileNames.includes(name)) {
                updateFormData(value);
            }
        };

        handleFormValueChange();
    };

    const submitForm =  (event, action) => {
        event.preventDefault();

        if (!checkBox) {
            setCheckBox(false);
        }

        if (action === 'next') {
            // if (isStep1Valid() && checkBox === true) {
            //     setCurrentStep(currentStep + 1);
            // }
            // if (currentStep === 2) {
                // if (isStep3Valid()) {
                //     setCurrentStep(currentStep + 1);
                //     console.log('data: ', directors);
                // }
            // }
            setCurrentStep(currentStep + 1);
        } else {
            setCurrentStep(currentStep - 1);
        }
    };
    

    return (
        <div style={{ overflow: "hidden" }}>
            {/* <div id='recaptcha-container' style={{ display: 'block' }}></div> */}
            {loading ?
                <div id="global-loader1">
                    <img src={loader} className="loader-img" alt="Loading...." />
                </div> : <>
                    <div className="flex-center py-10 RSPBprogressBars d-sms-none">
                        <Progress currentStep={currentStep} />
                    </div>
                    <CustomForm style={{ fontSize: `${(i18n.language === 'de' || i18n.language === 'fa') ? '18px' : ''}` }}>
                        {currentStep === 1 &&
                            <div className="justify">
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Full Corporate Name (As in Certificate of incorporation, Formation or Organization)</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.firstName || formData.step1.firstName) ? 'is-valid' : 'is-invalid')}
                                                    name="firstName"
                                                    type="text"
                                                    required=""
                                                    placeholder="John"
                                                    value={formData.step1.firstName}
                                                    onChange={(e) => {
                                                        // Remove non-alphanumeric characters except spaces
                                                        let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                        // Split the input into words, capitalize the first letter of each word, and join them back
                                                        let capitalizedInput = cleanedInput.split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                            .join(' ');

                                                        // Update the form value
                                                        formValueChange('step1', 'firstName', capitalizedInput);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`} >
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Establishment date</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.date || formData.step1.date) ? 'is-valid' : 'is-invalid')}
                                                    name="date"
                                                    type="date"
                                                    required=""
                                                    placeholder="dd-mm-yyyy"
                                                    value={formData.step1.date}
                                                    onChange={(e) => {
                                                        // Update the form value
                                                        formValueChange('step1', 'date', e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Corporate License No</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.corporateLicense || formData.step1.corporateLicense) ? 'is-valid' : 'is-invalid')}
                                                    name="corporateLicense"
                                                    type="text"
                                                    required=""
                                                    placeholder="Corporate License No"
                                                    value={formData.step1.corporateLicense}
                                                    onChange={(e) => {
                                                        // Update the form value
                                                        formValueChange('step1', 'corporateLicense', e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Trading Name</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.tradingName || formData.step1.tradingName) ? 'is-valid' : 'is-invalid')}
                                                    name="tradingName"
                                                    type="text"
                                                    required=""
                                                    placeholder="Trading Name"
                                                    value={formData.step1.tradingName}
                                                    onChange={(e) => {
                                                        // Remove non-alphanumeric characters except spaces
                                                        let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                        // Split the input into words, capitalize the first letter of each word, and join them back
                                                        let capitalizedInput = cleanedInput.split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ');

                                                        // Update the form value
                                                        formValueChange('step1', e.target.name, capitalizedInput);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Nature of Business</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.natureOfBusiness || formData.step1.natureOfBusiness) ? 'is-valid' : 'is-invalid')}
                                                    name="natureOfBusiness"
                                                    type="text"
                                                    required=""
                                                    placeholder="Nature of Business"
                                                    value={formData.step1.natureOfBusiness}
                                                    onChange={(e) => {
                                                        // Remove non-alphanumeric characters except spaces
                                                        let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                        // Split the input into words, capitalize the first letter of each word, and join them back
                                                        let capitalizedInput = cleanedInput.split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ');

                                                        // Update the form value
                                                        formValueChange('step1', e.target.name, capitalizedInput);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Country of incorporation</Label>
                                            <Input
                                                className={"mb-3 " + (!validationErrors.step1.country || formData.step1.country ? 'is-valid' : 'is-invalid')}
                                                type="select"
                                                name="country"
                                                onChange={(e) => {
                                                    const selectedCountryName = e.target.value;
                                                    const selectedCountry = countriesData.find(country => country.name === selectedCountryName);
                                                    if (selectedCountry) {
                                                        setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                                        formValueChange('step1', 'country', selectedCountry.name);
                                                        formValueChange('step1', 'countryCode', selectedCountry.iso2);
                                                    }
                                                }}
                                                value={formData.step1.country}
                                            >
                                                <option value="">-- Select --</option>
                                                {countryList.map((item, index) => (
                                                    <option key={index} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Telephone No</Label>
                                            <IntlTelInput
                                                name="phone"
                                                containerClassName="intl-tel-input"
                                                inputClassName={"form-control " + ((!validationErrors.step1.phone || formData.step1.phone) ? 'is-valid' : 'is-invalid')}
                                                style={{ width: '100%' }}
                                                maxLength={15}
                                                format="true"
                                                autoFormat={false}
                                                separateDialCode={false}
                                                disabled={checkEmail ? true : false}
                                                defaultCountry={selectedCountryCode || 'ae'}
                                                useMobileFullscreenDropdown={false}
                                                value={formData.step1.phone}
                                                onPhoneNumberChange={(status, value, countryData, number, isValidNumberPrecise, id, fullNumber) => {
                                                    if (value.length < 15) {
                                                        setPhoneNumber(countryData.dialCode);
                                                        formValueChange('step1', 'phone', value);
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Business Email Address</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + (!validationErrors.step1.email || formData.step1.email ? 'is-valid' : 'is-invalid')}
                                                    type="email"
                                                    name="email"
                                                    disabled={checkEmail ? true : false}
                                                    validation={{ required: true }}
                                                    value={formData.step1.email}
                                                    invalid={(emailError === 1 && formData.step1.email !== '' && 1) || validationErrors.step1.email}
                                                    valid={(emailError === 0 && formData.step1.email !== '' && 1) || !validationErrors.step1.email}
                                                    placeholder="john@gmail.com"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Company Website</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + (!validationErrors.step1.companyWebsite || formData.step1.companyWebsite ? 'is-valid' : 'is-invalid')}
                                                    type="text"
                                                    name="companyWebsite"
                                                    validation={{ required: true }}
                                                    value={formData.step1.companyWebsite}
                                                    placeholder="www.website.com"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Registered Office Address (P.O Box No)</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + (!validationErrors.step1.address || formData.step1.address ? 'is-valid' : 'is-invalid')}
                                                    type="text"
                                                    name="address"
                                                    validation={{ required: true }}
                                                    value={formData.step1.address}
                                                    placeholder="House No - Street - City - State"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Business Trading Address (If different from above)</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + (!validationErrors.step1.tradingAddress || formData.step1.tradingAddress ? 'is-valid' : 'is-invalid')}
                                                    type="email"
                                                    name="tradingAddress"
                                                    validation={{ required: true }}
                                                    value={formData.step1.tradingAddress}
                                                    placeholder="House No - Street - City - State"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Company tax residency</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + (!validationErrors.step1.taxResidency || formData.step1.taxResidency ? 'is-valid' : 'is-invalid')}
                                                    type="email"
                                                    name="taxResidency"
                                                    validation={{ required: true }}
                                                    value={formData.step1.taxResidency}
                                                    placeholder="House No - Street - City - State"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>TIN (if available)</Label>
                                            <div className="input-group">
                                                <Input className={"form-control "}
                                                    type="text"
                                                    name="tin"
                                                    validation={{ required: true }}
                                                    value={formData.step1.tin}
                                                    placeholder="1234"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`} >
                                    <Col md={12}>
                                        <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                            <Col md={8}>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>LEI No</Label>
                                                            <div className="input-group">
                                                                <Input className={"form-control " + (!validationErrors.step1.leiNo || formData.step1.leiNo ? 'is-valid' : 'is-invalid')}
                                                                    type="text"
                                                                    name="leiNo"
                                                                    validation={{ required: true }}
                                                                    value={formData.step1.leiNo}
                                                                    placeholder="leiNo"
                                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>GIIN (if applicable)</Label>
                                                            <div className="input-group">
                                                                <Input className={"form-control "}
                                                                    type="text"
                                                                    name="giin"
                                                                    validation={{ required: true }}
                                                                    value={formData.step1.email}
                                                                    placeholder="giin"
                                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>{t('description.part14')}</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="platform"
                                                                    className={formData.step1.platform ? "is-valid" : "is-invalid"}
                                                                    value={formData.step1.platform}
                                                                    onChange={(e) => {
                                                                        formValueChange('step1', e.target.name, e.target.value);
                                                                        setFilteredAccountTypes(filterAccountTypes(e.target.value));
                                                                        accountTypeValue(e.target.value);
                                                                    }}
                                                                >
                                                                    {platForm === 'MT4' && <option value="MT4">MT4</option>}
                                                                    <option value="MT5">MT5</option>
                                                                    <option value="CT">CTrader</option>
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>{t('description.part15')}</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="accountType"
                                                                    className={!validationErrors.step1.accountType || formData.step1.accountType ? 'is-valid' : 'is-invalid'}
                                                                    value={formData.step1.accountType}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            formValueChange('step1', e.target.name, e.target.value);
                                                                            const accountCurrency = filteredAccountTypes.filter((account) => account.Id === parseInt(e.target.value));
                                                                            formValueChange('step1', 'accountTypeCurrency', accountCurrency[0].Currency);
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="">-- Select --</option>
                                                                    {filteredAccountTypes && filteredAccountTypes.map((item, index) => (
                                                                        <option key={index} value={item['Id']}>
                                                                            {item['Account type']}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>{t('description.part16')}</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="password"
                                                                className={"form-control required input_password " + (validationErrors.step1.password ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step1.password}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step1', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>{t('description.part17')}</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="confirmPassword"
                                                                className={"form-control required input_password " + (validationErrors.step1.confirmPassword ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step1.confirmPassword}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step1', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <PasswordValidation password={formData.step1.password} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Label>Is the company regulated by any Financial Services Regulator?</Label>
                                        <div className={`input-group ms-2 mb-1 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="regulator"
                                                    id="regulatorYes"
                                                    value="Yes"
                                                    checked={formData.step1.regulator === true}
                                                    onChange={(e) => formValueChange('step1', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="regulatorYes">{t('description.part53')}</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="regulator"
                                                    id="regulatorNo"
                                                    value="No"
                                                    checked={formData.step1.regulator === false}
                                                    onChange={(e) => formValueChange('step1', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="regulatorNo">{t('description.part54')}</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step1.regulator &&
                                        <>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Name of Regulator</Label>
                                                    <div className="input-group">
                                                        <Input className={"form-control " + (!validationErrors.step1.regulatorName || formData.step1.regulatorName ? 'is-valid' : 'is-invalid')}
                                                            type="text"
                                                            name="regulatorName"
                                                            validation={{ required: true }}
                                                            value={formData.step1.regulatorName}
                                                            placeholder="john"
                                                            onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Regulatory Number</Label>
                                                    <div className="input-group">
                                                        <Input className={"form-control " + (!validationErrors.step1.regulatorNumber || formData.step1.regulatorNumber ? 'is-valid' : 'is-invalid')}
                                                            type="text"
                                                            name="regulatorNumber"
                                                            validation={{ required: true }}
                                                            value={formData.step1.regulatorNumber}
                                                            placeholder="1234"
                                                            onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </>}
                                    <Col md={12}>
                                        <div className={`input-group mb-3 mt-4 d-flex align-items-center ${checkBox === '' || checkBox === true ? 'text-primary' : 'text-danger'} ${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                            <label style={{ direction: (i18n.language === 'de' || i18n.language === 'fa') && 'rtl' }} className={`d-flex align-items-center ${(i18n.language === 'de' || i18n.language === 'fa') ? ' text-end' : ''}`}>
                                                <input
                                                    type="checkbox"
                                                    name="correctInfo"
                                                    className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'ms-3' : ''}`}
                                                    checked={Boolean(checkBox)}
                                                    onChange={() => {
                                                        setCheckBox(!checkBox)
                                                    }}
                                                />
                                                &nbsp;&nbsp;&nbsp; {t('description.part27')}
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {currentStep === 2 &&
                            <DirectorDetails directors={directors} setDirectors={setDirectors} />
                        }
                        {currentStep === 3 && (
                            <BeneficialOwner beneficialOwner={beneficialOwners} setBeneficialOwner={setBeneficialOwners} />
                        )}
                        {currentStep === 4 &&
                            ( <AuthorizedSignatory authorizedSignatory={authorizedSignatory} setAuthorizedSignatory={setAuthorizedSignatory}  /> )
                        } 
                        {currentStep === 5 &&
                            <>
                                <div className="w-100">
                                    <Row>
                                        <Col md={12} className="d-flex align-items-center gap-2"><h5>Financial Details</h5></Col>
                                    </Row>
                                </div>
                                <div className="justify w_100" style={{ marginBlock: "10px" }}>
                                    <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>What is the approximate size of the Company's liquid financial instrument profolio? <br /> (including cash deposits, investments but excluding any property)</Label>
                                                <Input
                                                    className={"mb-3 "}
                                                    type="text"
                                                    name="liquidFinancial"
                                                    value={formData.step2.liquidFinancial}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <Label>Has the Company even been declared or subject to bankruptcy or insolvency proceedings?</Label>
                                            <div className={`input-group ms-2 mb-1 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                                <div className="form-check form-check-inline">
                                                    <Input className="form-check-input"
                                                        type="radio"
                                                        name="companyDeclared"
                                                        id="companyDeclared"
                                                        value="Yes"
                                                        checked={formData.step2.companyDeclared === true}
                                                        onChange={(e) => formValueChange('step2', e.target.name, true)} />
                                                    <Label className="form-check-label" htmlFor="experiencedTradingYes">{t('description.part53')}</Label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Input className="form-check-input"
                                                        type="radio"
                                                        name="companyDeclared"
                                                        id="companyDeclared"
                                                        value="No"
                                                        checked={formData.step2.companyDeclared === false}
                                                        onChange={(e) => formValueChange('step2', e.target.name, false)} />
                                                    <Label className="form-check-label" htmlFor="experiencedTradingNo">{t('description.part54')}</Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>If so, please provide details</Label>
                                                <Input
                                                    className={"mb-3 "}
                                                    type="text"
                                                    name="residency"
                                                    value={formData.step2.declaredNote}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <Label>Has the Company or its directors ever been subject to any criminal, regulatory or legal proceedings?</Label>
                                            <div className={`input-group ms-2 mb-1 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                                <div className="form-check form-check-inline">
                                                    <Input className="form-check-input"
                                                        type="radio"
                                                        name="companyRecord"
                                                        id="companyRecord"
                                                        value="Yes"
                                                        checked={formData.step2.companyRecord === true}
                                                        onChange={(e) => formValueChange('step2', e.target.name, true)} />
                                                    <Label className="form-check-label" htmlFor="experiencedTradingYes">{t('description.part53')}</Label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Input className="form-check-input"
                                                        type="radio"
                                                        name="companyRecord"
                                                        id="companyRecord"
                                                        value="No"
                                                        checked={formData.step2.companyRecord === false}
                                                        onChange={(e) => formValueChange('step2', e.target.name, false)} />
                                                    <Label className="form-check-label" htmlFor="experiencedTradingNo">{t('description.part54')}</Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>If so, please provide details</Label>
                                                <Input
                                                    className={"mb-3 "}
                                                    type="text"
                                                    name="residency"
                                                    value={formData.step2.recordNote}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                        <Col md={12}>
                                            <Label>Annual Turnover USD</Label>
                                            <div className="input-group mb-3">
                                                <Input
                                                    className={"form-control " + ((!validationErrors.step2.annualTurover || formData.step2.annualTurover) ? 'is-valid' : 'is-invalid')}
                                                    name="annualTurover" type="text"
                                                    value={formData.step2.annualTurover}
                                                    required=""
                                                    onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                    placeholder="USD" />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <Label>Please provide details on the source of the Company's accumulated wealth.</Label>
                                            <div className="input-group mb-3">
                                                <Input
                                                    className={"form-control " + ((!validationErrors.step2.accumulatedWealth || formData.step2.accumulatedWealth) ? 'is-valid' : 'is-invalid')}
                                                    name="accumulatedWealth" type="text"
                                                    value={formData.step2.accumulatedWealth}
                                                    required=""
                                                    onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                    placeholder="USD" />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <Label>Do you operate as broker or intermediary for any third party?</Label>
                                            <div className={`input-group ms-2 mb-1 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                                <div className="form-check form-check-inline">
                                                    <Input className="form-check-input"
                                                        type="radio"
                                                        name="thirdParty"
                                                        id="thirdParty"
                                                        value="Yes"
                                                        checked={formData.step2.thirdParty === true}
                                                        onChange={(e) => formValueChange('step2', e.target.name, true)} />
                                                    <Label className="form-check-label" htmlFor="experiencedTradingYes">{t('description.part53')}</Label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Input className="form-check-input"
                                                        type="radio"
                                                        name="thirdParty"
                                                        id="thirdParty"
                                                        value="No"
                                                        checked={formData.step2.thirdParty === false}
                                                        onChange={(e) => formValueChange('step2', e.target.name, false)} />
                                                    <Label className="form-check-label" htmlFor="experiencedTradingNo">{t('description.part54')}</Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>If so, please provide details</Label>
                                                <Input
                                                    className={"mb-3 "}
                                                    type="text"
                                                    name="residency"
                                                    value={formData.step2.thirdPartyNote}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Funds available for trading with GoDo USD</Label>
                                                <Input
                                                    className={"mb-3 "}
                                                    type="text"
                                                    name="residency"
                                                    value={formData.step2.fundsAvailable}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Amount of intial deposit</Label>
                                                <Input
                                                    className={"mb-3 "}
                                                    type="text"
                                                    name="residency"
                                                    value={formData.step2.initialDeposit}
                                                >
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Net Assets (USD)</Label>
                                                <div className="input-group">
                                                    <Input
                                                        type="select"
                                                        name="platform"
                                                        className={formData.step2.netAssets ? "is-valid" : "is-invalid"}
                                                        value={formData.step2.netAssets}
                                                    >
                                                        <option value="Under 500,000">Under 500,000</option>
                                                        <option value="500,001 - 2 mil">500,001 - 2 mil</option>
                                                        <option value="above 2 mil - 10 mil">above 2 mil - 10 mil</option>
                                                        <option value="above 10 mil- 20 mil">above 10 mil- 20 mil</option>
                                                        <option value="above 20 mil">above 20 mil</option>
                                                    </Input>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                        <div className="w-100">
                                            <Row>
                                                <Col md={12} className="d-flex align-items-center gap-2"><h5>Banking Details (for all deposits and withdrawals)</h5></Col>
                                            </Row>
                                        </div>
                                        <div className="justify w_100" style={{ marginBlock: "10px" }}>
                                            <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Account Name</Label>
                                                        <Input
                                                            className={"mb-3 "}
                                                            type="text"
                                                            name="residency"
                                                            value={formData.step2.accountName}
                                                        >
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Bank Name and Branch</Label>
                                                        <Input
                                                            className={"mb-3 "}
                                                            type="text"
                                                            name="residency"
                                                            value={formData.step2.bankName}
                                                        >
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                                <Col md={6}>
                                                    <Label>Sort Code / BIC</Label>
                                                    <div className="input-group mb-3">
                                                        <Input
                                                            className={"form-control " + ((!validationErrors.step2.sortCode || formData.step2.sortCode) ? 'is-valid' : 'is-invalid')}
                                                            name="sortCode" type="text"
                                                            value={formData.step2.sortCode}
                                                            required=""
                                                            onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                            placeholder="ID Number" />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <Label>Bank Account No</Label>
                                                    <div className="input-group mb-3">
                                                        <Input
                                                            className={"form-control " + ((!validationErrors.step2.bankAccountNo || formData.step2.bankAccountNo) ? 'is-valid' : 'is-invalid')}
                                                            name="banlAccountNo" type="text"
                                                            value={formData.step2.bankAccountNo}
                                                            required=""
                                                            onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                            placeholder="ID Number" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                                <Col md={6}>
                                                    <Label>SWIFT</Label>
                                                    <div className="input-group mb-3">
                                                        <Input
                                                            className={"form-control "}
                                                            // className={"form-control " + (check && !formData.step2.idIssuedPlace && 'is-invalid')}
                                                            name="swift"
                                                            type="text"
                                                            // value={nationality}
                                                            required=""
                                                            placeholder="UAE" />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <Label>IBAN</Label>
                                                    <div className="input-group mb-3">
                                                        <Input
                                                            className={"form-control " + ((!validationErrors.step2.iban || formData.step2.iban) ? 'is-valid' : 'is-invalid')}
                                                            name="iban" type="text"
                                                            value={formData.step2.iban}
                                                            required=""
                                                            onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                            placeholder="ID Number" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </div>
                            </>
                        }
                        <div className="justify align-items-center d-flex flex-column flex-sm-row gap-y-2 mt-3 ">
                            {currentStep === 1 ? '' : <Button onClick={(e) => submitForm(e, 'previous')} className='primary-bg-color col buttonWidth mobile-disabled' style={{ height: '50px' }}>
                                {t('description.part51')}
                            </Button>}
                            <Button type="submit" onClick={currentStep === 5 ? (e) => submitForm(e, 'submit') : (e) => submitForm(e, 'next')} className='primary-bg-color col buttonWidth' style={{ height: '50px', background: '#26539F' }}>
                                {currentStep === 5 ? t('description.part77') : t('description.part28')}
                            </Button>
                        </div>
                        <div className="mt-3">
                            <Row md={12}>
                                <Col>
                                    <p className="text-danger">
                                        <Trans i18nKey="description.part29">
                                            <b>Exercise caution:</b> If uncertain about trading risks or uncomfortable with leveraging, it's advisable not to proceed with the application form, as trading entails the potential loss of your entire investment.
                                        </Trans>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        {/*<div>
                            <ReCAPTCHA
                                size="invisible"
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={handleRecaptcha}
                                ref={recaptchaRef}
                            />
                        </div>*/}
                    </CustomForm>
                </>}
        </div>
    )
}