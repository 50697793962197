import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PopAlert = ({ show, popUp, entity, entityName, entityChangeValue }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialLoad = useRef(true);

    useEffect(() => {
        // Ensure 'ptr' is present on initial load
        if (initialLoad.current) {
            initialLoad.current = false;
            const searchParams = new URLSearchParams(location.search);
            if (!searchParams.has('ptr') || !searchParams.has('rm')) {
                // searchParams.set('ptr', '10000'); // Default value for 'ptr'
                const newUrl = `${location.pathname}?${searchParams.toString()}`;
                navigate(newUrl, { replace: true });
            }
        }
    }, [location, navigate]);

    const handleClick = () => {
        // Get current search params
        const searchParams = new URLSearchParams(location.search);
    
        // Add or update new params
        searchParams.set('entity', entityName);
    
        // Construct new URL with updated params
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
    
        // Push the new URL to the history
        navigate(newUrl);
      };

    return (
        <div className="modal" tabIndex="-1" style={show ? {display: 'block'} : {display: 'none'} }>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <p>Do you want to change the entity?.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary w-25" data-bs-dismiss="modal" onClick={() => popUp(false)} >No</button>
                        <button type="button" className="btn btn-primary w-25" onClick={() => { entity(1); handleClick(); popUp(false)} }>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopAlert;
