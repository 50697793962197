import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';

const BeneficialOwner = ({ beneficialOwner, setBeneficialOwner }) => {

    const handleInputChange = (index, field, value) => {
        const updatedBeneficialOwner = [...beneficialOwner];
        updatedBeneficialOwner[index][field] = value;
        setBeneficialOwner(updatedBeneficialOwner);
    };

    const addBeneficialOwner = () => {
        if (beneficialOwner.length < 3) {
            setBeneficialOwner([...beneficialOwner, { title: '', fullName: '', jobTitle: '', dob: '', tin: '', holdings: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }]);
        }
    };

    const removeBeneficialOwner = (index) => {
        const updatedBeneficialOwner = beneficialOwner.filter((_, i) => i !== index);
        setBeneficialOwner(updatedBeneficialOwner);
    };

    const handleFileChange = (index, field, file) => {
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (file.size > maxFileSize) {
            alert('File size exceeds the limit of 5 MB.');
        } else if (!allowedExtensions.includes(fileExtension)) {
            alert('Please upload files with extension jpg, jpeg, png, pdf, heic.');
        } else {
            handleInputChange(index, field, file);
        }
    };

    return (
        <>
            <div className="w-100">
                <Row>
                    <Col md={12} className="d-flex align-items-center gap-2">
                        <h5>Ultimate Beneficial Owner</h5>
                        <Button color="primary" onClick={addBeneficialOwner}>+</Button>
                    </Col>
                </Row>
            </div>
            {beneficialOwner?.map((beneficialOwner, index) => (
                <div key={index} className="justify w-100" style={{ marginBlock: "10px" }}>
                    {index > 0 && (
                        <Button color="danger" onClick={() => removeBeneficialOwner(index)}>-</Button>
                    )}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Title</Label>
                                <Input className={"form-control " + (beneficialOwner.title ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={beneficialOwner.title}
                                    onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                                    placeholder="Enter full name"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input className={"form-control " + (beneficialOwner.fullName ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={beneficialOwner.fullName}
                                    onChange={(e) => handleInputChange(index, 'fullName', e.target.value)}
                                    placeholder="Enter contact number"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Job Title</Label>
                                <Input className={"form-control " + (beneficialOwner.jobTitle ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={beneficialOwner.jobTitle}
                                    onChange={(e) => handleInputChange(index, 'jobTitle', e.target.value)}
                                    placeholder="House No - Street - City - State"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Date Of Birth</Label>
                                <Input className={"form-control " + (beneficialOwner.dob ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={beneficialOwner.dob}
                                    onChange={(e) => handleInputChange(index, 'dob', e.target.value)}
                                    placeholder="Enter TIN"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>TIN (If available)</Label>
                                <Input className={"form-control " + (beneficialOwner.tin ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={beneficialOwner.tin}
                                    onChange={(e) => handleInputChange(index, 'tin', e.target.value)}
                                    placeholder="House No - Street - City - State"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Holdings (%)</Label>
                                <Input className={"form-control " + (beneficialOwner.holdings ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={beneficialOwner.holdings}
                                    onChange={(e) => handleInputChange(index, 'holdings', e.target.value)}
                                    placeholder="Enter TIN"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'proofOfIdentity', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity Back</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'proofOfIdentityBack', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Residential Proof of Address</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'resProofOfAddress', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
};

export default BeneficialOwner;
