import React from 'react';
import { useTranslation } from 'react-i18next';

/*const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[@%&])[A-Za-z\d@%&]{8}$/;
    return regex.test(password);
}*/

const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
        errors.push("Password must be exactly or greater then 8 characters long.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
        errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[@#&$])/.test(password)) {
        errors.push("Password must contain one special character (@, #, & or $).");
    }
    if (!/^[A-Za-z\d@#&$]+$/.test(password)) {
        errors.push("Password can only contain letters, digits, and special characters (@, #, &, $).");
    }

    return {
        isValid: errors.length === 0,
        errors
    };
}
const PasswordValidation = ({ password }) => {
   //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@%&])(?=.*[^a-zA-Z\d\s@%&]).{8,15}$/;
   //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@%&]).{8,8}$/;

    const { t, i18n } = useTranslation();

    const isValid = validatePassword(password);
    // if (isValid.isValid) {
    //     console.log("Password is valid.");
    // } else {
    //     console.log("Password is invalid:", isValid.errors);
    // }
    return (
        <div className="form-row passwordValid">
            <div className="form-holder">
                <h4>{t('description.part20')}</h4>
                <span style={{ color: isValid ? 'green' : 'red', top: '6px', position: 'relative' }} className="password_warning"></span>
                <p id="letter" className={`${ /[a-z]/.test(password) ? 'valid' : 'invalid'}`}>
                <b>{t('description.part21')}</b>
                </p>
                <p id="capital" className={`${ /[A-Z]/.test(password) ? 'valid' : 'invalid'}`}>
                <b>{t('description.part22')}</b>
                </p>
                <p id="number" className={`${ /\d/.test(password) ? 'valid' : 'invalid'}`}>
                <b>{t('description.part23')}</b>
                </p>
                <p id="length" style={{ direction: i18n.language === 'de' ? 'rtl' : 'ltr' }} className={`${ password.length >= 8 ? 'valid' : 'invalid'}`}>
                <b>{t('description.part24')}</b>
                </p>
                <p id="maxlength" style={{ direction: i18n.language === 'de' ? 'rtl' : 'ltr' }} className={`${ password.length >= 8 && password.length <= 12 ? 'valid' : 'invalid'}`}>
                <b>{t('description.part25')}</b>
                </p>
                <p id="specialChar" className={`${ /[@#&$]/.test(password) ? 'valid' : 'invalid'}`}>
                <b>{t('description.part26')}</b>
                </p>
            </div>
        </div>
    );
};

export default PasswordValidation;
