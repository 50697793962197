import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';

const AuthorizedSignatory = ({ authorizedSignatory, setAuthorizedSignatory }) => {

    const handleInputChange = (index, field, value) => {
        const updatedauthorizedSignatory = [...authorizedSignatory];
        updatedauthorizedSignatory[index][field] = value;
        setAuthorizedSignatory(updatedauthorizedSignatory);
    };

    const addauthorizedSignatory = () => {
        if (authorizedSignatory.length < 3) {
            setAuthorizedSignatory([...authorizedSignatory, { fullName: '', jobTitle: '', email: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }]);
        }
    };

    const removeauthorizedSignatory = (index) => {
        const updatedauthorizedSignatory = authorizedSignatory.filter((_, i) => i !== index);
        setAuthorizedSignatory(updatedauthorizedSignatory);
    };

    const handleFileChange = (index, field, file) => {
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (file.size > maxFileSize) {
            alert('File size exceeds the limit of 5 MB.');
        } else if (!allowedExtensions.includes(fileExtension)) {
            alert('Please upload files with extension jpg, jpeg, png, pdf, heic.');
        } else {
            handleInputChange(index, field, file);
        }
    };

    return (
        <>
            <div className="w-100">
                <Row>
                    <Col md={12} className="d-flex align-items-center gap-2">
                        <h5>Authorized Signatory Section</h5>
                        <Button color="primary" onClick={addauthorizedSignatory}>+</Button>
                    </Col>
                </Row>
            </div>
            {authorizedSignatory?.map((authorizedSignatory, index) => (
                <div key={index} className="justify w-100" style={{ marginBlock: "10px" }}>
                    {index > 0 && (
                        <Button color="danger" onClick={() => removeauthorizedSignatory(index)}>-</Button>
                    )}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input className={"form-control " + (authorizedSignatory.fullName ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={authorizedSignatory.fullName}
                                    onChange={(e) => handleInputChange(index, 'fullName', e.target.value)}
                                    placeholder="Enter contact number"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Job Title</Label>
                                <Input className={"form-control " + (authorizedSignatory.jobTitle ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={authorizedSignatory.jobTitle}
                                    onChange={(e) => handleInputChange(index, 'jobTitle', e.target.value)}
                                    placeholder="House No - Street - City - State"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input className={"form-control " + (authorizedSignatory.email ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={authorizedSignatory.email}
                                    onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                    placeholder="Enter TIN"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'proofOfIdentity', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity Back</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'proofOfIdentityBack', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Residential Proof of Address</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'resProofOfAddress', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
};

export default AuthorizedSignatory;
