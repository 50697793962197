import React, { useState } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import { useTranslation } from 'react-i18next';



const LanguageTranslation = ({ className }) => {
    const { i18n } = useTranslation();

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [languageName, setLanguageName] = useState(i18n.language === 'de' ? 'Arabic' : i18n.language === 'fa' ? 'Persian' : i18n.language === 'fre' ? 'French' : 'English' );

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const languageSelectHandler = (lang) => {

        if (lang === 'en') {
            i18n.changeLanguage(lang);
            setLanguageName('English');

        } else if (lang === 'de') {
            i18n.changeLanguage(lang);
            setLanguageName('Arabic');
        
        } else if (lang === 'fre') {
            i18n.changeLanguage(lang);
            setLanguageName('French');

        } else {
            i18n.changeLanguage(lang);
            setLanguageName('Persian');
        }

    };

    return (
        <div className={`changeDropdown m-0 ${className}`}>
            <div className={`dropdown ${className}`}>
                <button onClick={toggleDropdown} className={`dropbtn ${className}`}>
                    {languageName}
                    <IoMdArrowDropdown className=" ms-1" />
                </button>
                {dropdownVisible && (
                    <div id="myDropdown1" className="dropdown-content">
                        <div onClick={() => { languageSelectHandler('en'); toggleDropdown() }}>
                            English
                        </div>
                        <div onClick={() => { languageSelectHandler('de'); toggleDropdown() }}>
                            ArabIc
                        </div>
                        <div onClick={() => { languageSelectHandler('fre'); toggleDropdown() }}>
                            French
                        </div>
                        <div onClick={() => { languageSelectHandler('fa'); toggleDropdown() }}>
                            Persian
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LanguageTranslation
