import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';

const DirectorDetails = ({ directors, setDirectors }) => {
    // const [directors, setDirectors] = useState([
    //     { fullName: '', phone: '', address: '', tin: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }
    // ]);

    const handleInputChange = (index, field, value) => {
        const updatedDirectors = [...directors];
        updatedDirectors[index][field] = value;
        setDirectors(updatedDirectors);
    };

    const addDirector = () => {
        if (directors.length < 3) {
            setDirectors([...directors, { fullName: '', phone: '', address: '', tin: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '' }]);
        }
    };

    const removeDirector = (index) => {
        const updatedDirectors = directors.filter((_, i) => i !== index);
        setDirectors(updatedDirectors);
    };

    const handleFileChange = (index, field, file) => {
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (file.size > maxFileSize) {
            alert('File size exceeds the limit of 5 MB.');
        } else if (!allowedExtensions.includes(fileExtension)) {
            alert('Please upload files with extension jpg, jpeg, png, pdf, heic.');
        } else {
            handleInputChange(index, field, file);
        }
    };

    return (
        <>
            <div className="w-100">
                <Row>
                    <Col md={12} className="d-flex align-items-center gap-2">
                        <h5>Please list all directors of the Company (or equivalent).</h5>
                        <Button color="primary" onClick={addDirector}>+</Button>
                    </Col>
                </Row>
            </div>
            {directors?.map((director, index) => (
                <div key={index} className="justify w-100" style={{ marginBlock: "10px" }}>
                    {index > 0 && (
                        <Button color="danger" onClick={() => removeDirector(index)}>-</Button>
                    )}
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input className={"form-control " + (director.fullName ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={director.fullName}
                                    onChange={(e) => handleInputChange(index, 'fullName', e.target.value)}
                                    placeholder="Enter full name"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Contact Number</Label>
                                <Input className={"form-control " + (director.phone ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={director.phone}
                                    onChange={(e) => handleInputChange(index, 'phone', e.target.value)}
                                    placeholder="Enter contact number"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Residential Address</Label>
                                <Input className={"form-control " + (director.address ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={director.address}
                                    onChange={(e) => handleInputChange(index, 'address', e.target.value)}
                                    placeholder="House No - Street - City - State"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>TIN</Label>
                                <Input className={"form-control " + (director.tin ? 'is-valid' : 'is-invalid')}
                                    type="text"
                                    value={director.tin}
                                    onChange={(e) => handleInputChange(index, 'tin', e.target.value)}
                                    placeholder="Enter TIN"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'proofOfIdentity', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Proof of Identity Back</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'proofOfIdentityBack', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Residential Proof of Address</Label>
                                <Input
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                    onChange={(e) => handleFileChange(index, 'resProofOfAddress', e.target.files[0])}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
};

export default DirectorDetails;
